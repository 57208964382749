// src/components/NavigationBar.tsx

'use client';

import React from 'react';
import { useRouter } from 'next/router';
import { Home, User, LogOut, ArrowLeft } from 'lucide-react';
import { signOut } from 'next-auth/react';

const navItems = [
  { name: 'Home', path: '/dashboard', icon: Home },
  { name: 'Go Back', action: 'goBack', icon: ArrowLeft },
  { name: 'Profile', path: '/employee', icon: User },
];

const NavigationBar: React.FC = () => {
  const router = useRouter();

  const handleGoBack = () => {
    router.back();
  };

  const handleSignOut = async () => {
    await signOut({ callbackUrl: '/login' });
  };

  const handleNavigation = (item: { path?: string; action?: string }) => {
    if (item.action === 'goBack') {
      handleGoBack();
    } else if (item.path) {
      router.push(item.path);
    } else {
      console.error('Navigation path is undefined');
      router.push('/dashboard'); // Fallback to dashboard if path is undefined
    }
  };

  return (
    <nav className="fixed top-0 left-0 w-full bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 shadow-md z-10 p-4 flex justify-between items-center">
      <div className="flex items-center">
        <div className="text-2xl font-bold text-white">EGLO</div>
      </div>
      <div className="flex items-center space-x-4 sm:space-x-6">
        {navItems.map((item) => {
          const Icon = item.icon;
          return (
            <button
              key={item.name}
              onClick={() => handleNavigation(item)}
              className="flex items-center text-white hover:text-gray-200 transition-colors"
              aria-label={item.name}
            >
              <Icon className="w-5 h-5 mr-1" aria-hidden="true" />
              <span className="hidden sm:inline">{item.name}</span>
            </button>
          );
        })}
        <button
          onClick={handleSignOut}
          className="flex items-center text-white hover:text-gray-200 transition-colors"
          aria-label="Sign Out"
        >
          <LogOut className="w-5 h-5 mr-1" aria-hidden="true" />
          <span className="hidden sm:inline">Sign Out</span>
        </button>
      </div>
    </nav>
  );
};

export default NavigationBar;
