// src/pages/_app.tsx

import React from 'react'
import '../styles/globals.css'
import type { AppProps } from 'next/app'
import { SessionProvider } from 'next-auth/react'
import MainLayout from '../layouts/MainLayout'
import { useRouter } from 'next/router'

export default function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter()
  const noLayoutNeeded = ['/login', '/signup', '/']

  const isNoLayoutPage = noLayoutNeeded.includes(router.pathname)

  return (
    <SessionProvider session={pageProps.session}>
      {isNoLayoutPage ? (
        <Component {...pageProps} />
      ) : (
        <MainLayout>
          <Component {...pageProps} />
        </MainLayout>
      )}
    </SessionProvider>
  )
}
