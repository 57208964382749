// src/layouts/MainLayout.tsx

import React, { useEffect } from 'react';
import { useSession } from 'next-auth/react';
import NavigationBar from '../components/hr-app-src-components-navigation-bar';
import SidebarNavigation from '../components/hr-app-src-components-sidebar-navigation';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const { data: session, status } = useSession();

  useEffect(() => {
    if (session) {
      console.log('User session:', session);
      console.log('User role:', session.user.role);
    }
  }, [session]);

  if (status === 'loading') {
    return null;
  }

  if (session) {
    const userRole = session.user.role;
    console.log('Rendering MainLayout with userRole:', userRole);

    return (
      <div className="flex min-h-screen bg-white">
        <SidebarNavigation userRole={userRole} />
        <div className="flex-1 ml-0 md:ml-64 bg-white relative">
          <NavigationBar />
          <main className="pt-16 p-4 bg-white">{children}</main>
        </div>
      </div>
    );
  }

  return <div>{children}</div>;
};

export default MainLayout;
