// src/components/SidebarNavigation.tsx

'use client';

import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { Briefcase, UserCheck, Users, Layout, Settings, FileText } from 'lucide-react';
import Link from 'next/link';

interface SidebarNavigationProps {
  userRole: string;
}

const allNavigationItems = [
  { name: 'Admin Dashboard', path: '/admin', icon: UserCheck, roles: ['admin'] },
  { name: 'HR Manager', path: '/hr', icon: Briefcase, roles: ['hr', 'admin'] },
  { name: 'Department Head', path: '/department-head', icon: Layout, roles: ['Department Head', 'admin', 'General Manager'] },
  { name: 'Employee Dashboard', path: '/employee', icon: Users, roles: ['employee', 'Department Head', 'admin', 'hr', 'General Manager'] },
  { name: 'Settings', path: '/settings', icon: Settings, roles: ['admin'] },
  { name: 'Reports', path: '/reports', icon: FileText, roles: ['admin', 'hr'] },
  // Add more navigation items as needed
];

const SidebarNavigation: React.FC<SidebarNavigationProps> = ({ userRole }) => {
  const router = useRouter();

  useEffect(() => {
    console.log('SidebarNavigation rendered with userRole:', userRole);
  }, [userRole]);

  const items = allNavigationItems.filter(item => 
    item.roles.some(role => role.toLowerCase() === userRole.toLowerCase())
  );

  console.log('Filtered navigation items:', items);

  return (
    <aside className="fixed top-0 left-0 h-full w-64 bg-gradient-to-b from-indigo-600 to-purple-700 shadow-md p-4 z-10 hidden md:block">
      <nav className="flex flex-col space-y-4 mt-16">
        {items.map((item) => {
          const Icon = item.icon;
          const isActive = router.pathname === item.path;

          return (
            <Link href={item.path} key={item.name} legacyBehavior>
              <a
                className={`flex items-center p-2 text-white hover:text-gray-200 hover:bg-indigo-800 rounded-md transition-colors duration-200 ${
                  isActive ? 'bg-indigo-800 text-gray-200' : ''
                }`}
                aria-label={`Navigate to ${item.name} page`}
              >
                <Icon className="w-5 h-5" aria-hidden="true" />
                <span className="ml-3">{item.name}</span>
              </a>
            </Link>
          );
        })}
      </nav>
    </aside>
  );
};

export default SidebarNavigation;
